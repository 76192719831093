import React, { useEffect, useState, useCallback } from "react";
import HeaderNav from "./header-nav-view";
import throttle from "../../utils/throttle";

const HeaderNavContainer: React.FC = () => {
    const [backgroundOpacity, setBackgroundOpacity] = useState<number>(0.2);

    const handleScroll = () => {
        // transform background opacity from minOpacity (no scroll) to 1.0 (scroll >= maxOpacityThreshold)
        const minOpacity = 0.2;
        const maxOpacityThreshold = window.innerHeight / 3.0;
        const opacity = Math.max(Math.min(window.pageYOffset / maxOpacityThreshold, 1.0), minOpacity);

        setBackgroundOpacity(opacity);
    };

    const throttled = useCallback(throttle(handleScroll, 16), []);

    useEffect(() => {
        window.addEventListener("scroll", throttled);

        return () => {
            window.removeEventListener("scroll", throttled);
        };
    }, [throttled]);

    return <HeaderNav backgroundColor={`rgba(1, 57, 95, ${backgroundOpacity})`} />;
};

export default HeaderNavContainer;