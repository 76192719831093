import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import Footer from "../footer";
import HeaderNav from "../header-nav";
// import { renderToStaticMarkup } from "react-dom/server";
import "./App.scss";

library.add(fab, fas, far);

export default class App extends React.Component {
    // constructor(props: any) {
    //   super(props);

    //   this.props.initialize({
    //     options: { renderToStaticMarkup },
    //   });
    // }

    usePathname = () => {
        return window.location.href;
    };

    render() {
        return (
            <div className="App">
                <HeaderNav />
                <Routes />
                <Footer />
                <CookieConsent
                    buttonText="Schließen"
                    containerClasses="p-3 align-items-center"
                    buttonClasses="my-0 mx-auto mx-sm-0"
                >
                    <div className="text-center">
                        Mit der Benutzung dieser Website stimmst du der Verwendung von
                        Cookies und der{" "}
                        <Link to="/datenschutz">Datenschutzerklärung</Link> zu.
                    </div>
                </CookieConsent>
            </div>
        );
    }
}
