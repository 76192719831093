import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as ReactDOMClient from "react-dom/client";
import "./assets/custom_bootstrap.scss";
import App from "./components/app/App";
import ScrollToTop from "./components/app/scroll-to-top";
import AuthContext from "./providers/AuthProvider";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://e65fa1cd8b964529bb2384512c1fb2da@o563109.ingest.sentry.io/5702789",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

    TagManager.initialize({
        gtmId: "GTM-53VJJSR",
        auth: "JCtN6kKsEV5N6R4AtnlU_w",
        preview: "env-1"
    });
}
else {
    TagManager.initialize({
        gtmId: "GTM-53VJJSR",
        auth: "Nn5fP-AArOe1cQI9U6PB8w",
        preview: "env-7"
    });
}

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
    <HelmetProvider>
        <AuthContext>
            <Router>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </AuthContext>
    </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
