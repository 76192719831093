import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Alert, Button, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import GLOBALS from "../../globals";
import fetchWithCamelCase from "utils/fetch";

interface NewsletterModalProps {
    show: boolean;
}

interface NewsletterModalState {
    show: boolean;
}

const ValidationSchema = Yup.object().shape({
    mail: Yup.string()
        .email("Email Adresse ist ungültig")
        .max(255, "Max. 255 Zeichen")
        .required("Email darf nicht leer sein"),
    isDataPolicyAccepted: Yup.bool()
        .oneOf(
            [true],
            "Bitte stimme der zweck-gebundenen Verarbeitung deiner Daten zu"
        )
        .required(
            "Bitte stimme der zweckgebundenen Verarbeitung deiner Daten zu"
        ),
});

export default class NewsletterModal extends Component<
NewsletterModalState,
NewsletterModalProps
> {
    constructor(props: any) {
        super(props);

        this.state = {
            show: false,
        };
    }

    handleClose = () => {
        this.setState({
            show: false,
        });
    };

    handleShow = () => {
        this.setState({
            show: true,
        });
    };

    getModal = () => {
        return (
            <>
                <Button onClick={this.handleShow}>Zum Newsletter</Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Anmeldung Newsletter</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            mail: "",
                            isDataPolicyAccepted: false,
                        }}
                        validationSchema={ValidationSchema}
                        onSubmit={(values, actions) => {
                            fetchWithCamelCase(`${GLOBALS.API_URL}/newsletters/`, {
                                method: "POST",
                                body: JSON.stringify(values),
                            }).then(responseJson => {
                                actions.setSubmitting(false);

                                // Notify user of success or failure
                                if (responseJson.id) {
                                    actions.resetForm();
                                    actions.setStatus({
                                        success: true,
                                        msg: "Erfolgreich angemeldet.",
                                    });
                                }
                                else {
                                    actions.setStatus({
                                        success: false,
                                        msg: "Anmeldung fehlgeschlagen.",
                                    });
                                    actions.setErrors(responseJson);
                                }

                                // Hide notification after three seconds
                                setTimeout(function () {
                                    actions.setStatus({
                                        msg: "",
                                    });
                                }, 3000);
                            }).catch((e) => {
                                console.error(e);
                            });
                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form>
                                <Modal.Body>
                                    <Row className="form-group">
                                        <div className="col">
                                            <Field
                                                name="mail"
                                                type="email"
                                                placeholder="Email*"
                                                className={
                                                    "form-control" +
                                                    (errors.mail && touched.mail ? " is-invalid" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name="mail"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="form-group">
                                        <div className="col d-flex ">
                                            <Field
                                                type="checkbox"
                                                name="isDataPolicyAccepted"
                                                className={
                                                    "mr-2" +
                                                    (errors.isDataPolicyAccepted &&
                                                        touched.isDataPolicyAccepted
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                            />
                                            <label
                                                htmlFor="isDataPolicyAccepted"
                                                className="small m-0 w-90"
                                            >
                                                Ich bin damit einverstanden, dass edon die über dieses
                                                Formular bereitgestellten Daten verarbeitet und
                                                speichert. Ich stimme den{" "}
                                                <Link className="links" to={"/agb"}>
                                                    AGB
                                                </Link>{" "}
                                                und der{" "}
                                                <Link className="links" to={"/datenschutz"}>
                                                    Datenschutzerklärung
                                                </Link>{" "}
                                                zu.
                                            </label>
                                            <ErrorMessage
                                                name="isDataPolicyAccepted"
                                                component="div"
                                                className="invalid-feedback w-75"
                                            />
                                        </div>
                                    </Row>
                                    {status?.msg && (
                                        <Row>
                                            <div className="col d-flex justify-content-center">
                                                <Alert variant={status.success ? "success" : "danger"}>
                                                    {status.msg}
                                                </Alert>
                                            </div>
                                        </Row>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Abbrechen
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <FontAwesomeIcon icon="spinner" spin />}
                                        {!isSubmitting && "Jetzt Anmelden"}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    />
                </Modal>
            </>
        );
    };

    render() {
        return this.getModal();
    }
}
