export interface BankAccount {
    id: number;
    iban: string;
    swiftCode: string;
    isDirectDebitEdonUgEnabled: boolean;
    isDirectDebitFairgebenEvEnabled: boolean;
}

export const BANK_ACCOUNT_DEFAULT = {
    id: -1,
    iban: "",
    swiftCode: "",
    isDirectDebitEdonUgEnabled: false,
    isDirectDebitFairgebenEvEnabled: false,
};

export interface Address {
    id: number;
    name: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
}

export const ADDRESS_DEFAULT = {
    id: -1,
    name: "",
    street: "",
    zipCode: "",
    city: "",
    country: "",
};

export interface AccountingInformation {
    id: number;
    name: string;
    defaultPurposeOfTransfer: string;
    address: Address,
    bankAccount: BankAccount,
}

export const ACCOUNTING_INFORMATION_DEFAULT = {
    id: -1,
    name: "",
    address: ADDRESS_DEFAULT,
    bankAccount: BANK_ACCOUNT_DEFAULT,
    defaultPurposeOfTransfer: "",
};