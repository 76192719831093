import React, { useContext, useEffect, useState } from "react";
import { USER_DEFAULT_VALUE, type User } from "types";
import { AuthContext } from "./AuthProvider";
import GLOBALS from "../globals";
import fetchWithCamelCase from "utils/fetch";

export interface UserContextInterface {
    isLoading: boolean;
    isFetched: boolean;
    user: User;
    setUser: (user: User) => void;
    fetchUser: () => void;
}

const USER_CONTEXT_DEFAULT_VALUE = {
    isLoading: false,
    isFetched: false,
    user: USER_DEFAULT_VALUE,
    setUser: () => {
        console.error("Not implemented.");
    },
    fetchUser: () => {
        console.error("Not implemented.");
    },
};

export const UserContext = React.createContext<UserContextInterface>(USER_CONTEXT_DEFAULT_VALUE);

const UserProvider = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [user, setUser] = useState<User>(USER_DEFAULT_VALUE);
    const { token, user: userSkeleton } = useContext(AuthContext);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = () => {
        setIsLoading(true);
        fetchWithCamelCase(`${GLOBALS.API_URL}/users/${userSkeleton.id}/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then(responseJson => {
                setIsFetched(true);
                setIsLoading(false);
                if (responseJson.id) {
                    setUser(responseJson);
                }
                // TODO: Display error message, remove localStorage and redirect to login
                else {
                    console.error(responseJson);
                }
            }).catch((e) => {
                console.error(e);
            });
    };

    const value = {
        isLoading,
        isFetched,
        user,
        setUser,
        fetchUser
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;