export interface Discount {
    id: number,
    dateStart: string,
    dateExpire?: string,
    name: string,
    discount_amount: number,
    discount_percentage: number
}

export interface Subscription {
    id: number,
    dateStart: string,
    dateRenewal: string,
    dateCanceled?: string,
    name: string,
    isTrial: boolean,
    priceNet: number,
    billingPeriodMonth: string,
    price: number,
    discounts: Discount[]
};

export const DEFAULT_DISCOUNT = {
    id: -1,
    dateStart: "",
    dateExpire: "",
    name: "",
    discountAmount: 0,
    discountPercentage: 0
};

export const DEFAULT_SUBSCRIPTION = {
    id: -1,
    dateStart: "",
    dateEnd: "",
    name: "",
    isTrial: true,
    priceNet: 0,
    billingPeriodMonth: "",
    price: 0,
    discounts: []
};

export interface PricingFeature {
    name: string;
    isCovered: boolean;
}

export interface BillingPeriodDetails {
    period: number;
    periodName: string
    billingName: string;
    discountPercentage: number;
}

export const DEFAULT_BILLING_PERIOD_DETAILS = {
    period: 1,
    periodName: "Monatlich",
    billingName: " / Monat",
    discountPercentage: 0
}

export enum BillingPeriodTypes {
    monthly = "monthly",
    haltYearly = "halt-yearly",
    anual = "anual",
}

export interface SubscriptionPlan {
    id: number
    paypalPlanId?: string
    name: string;
    price: number;
    defaultPeriod: number
    billingPeriodDetails?: BillingPeriodDetails[];
    isMostPopular?: boolean;
    callToAction?: string;
    trailPeriodDays?: number;
    features: PricingFeature[];
}

export interface Package {
    id: string;
    name: string;
    price: number;
    description: string;
    unit: string;
    defaultQuantity: number;
    isQuantityEditable: boolean;
}