import { type AccountingInformation } from "./accounting-information";
import { type Organisation, ORGANISATION_DEFAULT_VALUE } from "./organisation";

export interface Shop {
    id: number;
    name: string;
    owner: number;
    organisation: Organisation;
    homepage: string;
    image?: string;
    image1080?: string;
    image720?: string;
    image300?: string;
    image100?: string;
    isActive: boolean;
    isTest: boolean;
    conversionRate?: number;
    shopSystem?: string;
    averageBasketValue?: number;
    monthlyUserCount?: number;
    cooperationLimit: number;
    accountingInformation?: AccountingInformation;
}

export const SHOP_DEFAULT_VALUE: Shop = {
    id: -1,
    name: "",
    owner: -1,
    homepage: "",
    isActive: false,
    isTest: false,
    organisation: ORGANISATION_DEFAULT_VALUE,
    cooperationLimit: 1,
    accountingInformation: undefined,
};
