import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import GLOBALS from "../../globals";
import NewsletterModal from "../newsletter-modal/newsletter-modal-view";
import "./footer.scss";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer bg-columbiablue py-4">
                <Container>
                    <Row
                        className={
                            "text-md-left text-center justify-content-md-left justify-content-center"
                        }
                    >
                        <div className="col-4 col-lg-2 mb-4">
                            <img src={`${GLOBALS.IMG_URL}/logo-128.webp`} alt={"edon Logo"} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mb-4">
                            <h4>Unternehmen</h4>
                            <Link to="/about">About</Link>
                            <Link to="/impressum">Impressum</Link>
                            <Link to="/datenschutz">Datenschutz</Link>
                            <Link to="/agb">AGB</Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mb-4">
                            <h4>Services</h4>
                            <Link to="/fairgeben">FairGeben</Link>
                            <Link to="/projektpool">Projektpool</Link>
                            {/* <Link to="/givegreen">GiveGreen</Link> */}
                            {/* <Link to="/pricing-plentymarkets">Pricing</Link> */}
                            <Link to="/faq">FAQ</Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mb-4">
                            <h4>Mitmachen</h4>
                            <Link to="/jobs">Jobs</Link>
                            <Link to="/partner-werden">Partner Werden</Link>
                        </div>
                        <div className="col col-md-4 d-lg-none mb-4"></div>
                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mb-4">
                            <h4>News</h4>
                            <Link to="/blog">Blog</Link>
                            <NewsletterModal show={false} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mb-4">
                            <h4>Social</h4>
                            <div className="d-flex flex-row justify-content-md-start justify-content-center">
                                <a href="https://www.facebook.com/teamedon" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
                                </a>
                                <a
                                    href="https://www.instagram.com/edon_electronic_donations"
                                    target="_blank"
                                    className="ml-1" rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/edon-electronic-donations"
                                    target="_blank"
                                    className="ml-1" rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-1 mb-4"></div>
                    </Row>
                </Container>
            </div>
        );
    }
}
