import React, { useEffect, useState } from "react";

export interface User {
    id: number;
    username: string;
}

export const USER_DEFAULT_VALUE = {
    id: -1,
    username: "",
};

export interface Auth {
    token: string;
    setToken: (token: string) => void;
    user: User;
    setUser: (user: User) => void;
}

export const AUTH_DEFAULT_VALUE = {
    token: "",
    setToken: () => { },
    user: USER_DEFAULT_VALUE,
    setUser: () => { },
};

export const AuthContext = React.createContext<Auth>(AUTH_DEFAULT_VALUE);

// https://medium.com/@ni3t/reacts-usestate-and-context-for-auth-routing-78347da1d6f
const AuthProvider = ({ children }: any) => {
    const prevToken = window.localStorage.getItem("token") ?? "";
    const prevUser = JSON.parse(window.localStorage.getItem("user") ?? "{}");

    const [token, setToken] = useState(prevToken);
    const [user, setUser] = useState(prevUser);

    useEffect(() => {
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("user", JSON.stringify(user));
    }, [token, user]);

    const defaultContext = {
        token,
        setToken,
        user,
        setUser,
    };

    return (
        <AuthContext.Provider value={defaultContext}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;