import { type Shop } from "./shop";
import { type Project } from "./project";
import { type Organisation, ORGANISATION_DEFAULT_VALUE } from "./organisation";

export enum CustomerTypes {
    INDIVIDUAL = "INDIVIDUAL",
    SHOP = "SHOP",
    PROJECT = "PROJECT",
}

export interface User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    shop?: Shop;
    projects: Project[];
    organisation: Organisation;
    extras: any;
    customerType: CustomerTypes,
    isStaff: boolean;
    onboardingChecklist?: {
        hasCompletedAccountSetup: boolean;
        hasActiveSubscription: boolean;
        // shop specific checks
        hasCreatedCooperation: boolean;
        hasConnectedPluginOrApp: boolean;
        hasCreatedTestDonation: boolean;
        // nonprofit specific checks
        hasCreatedProject: boolean
    }
}

export const USER_DEFAULT_VALUE = {
    id: -1,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    projects: [],
    organisation: ORGANISATION_DEFAULT_VALUE,
    extras: {},
    customerType: CustomerTypes.INDIVIDUAL,
    isStaff: false,
};

export type onUserChangedHandler = (user: User) => void;