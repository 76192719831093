export enum OrganisationTypes {
    SHOP = "shop",
    NONPROFIT = "nonprofit",
    CORPORATE = "corporate",
}

export interface Organisation {
    id: number;
    name: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    exemptUntil: string;
    organisationType: string;
}

export const ORGANISATION_DEFAULT_VALUE = {
    id: -1,
    name: "",
    street: "",
    zipCode: "",
    city: "",
    country: "",
    exemptUntil: "",
    organisationType: "",
};