import { type AccountingInformation } from "./accounting-information";
import { type Organisation, ORGANISATION_DEFAULT_VALUE } from "./organisation";
import { type Survey } from "./survey";

export interface Project {
    id: number;
    name: string;
    owner: number;
    imageHorizontal?: string;
    imageHorizontal1080?: string;
    imageHorizontal720?: string;
    imageHorizontal300?: string;
    imageHorizontal100?: string;
    imageVertical?: string;
    imageVertical1080?: string;
    imageVertical720?: string;
    imageVertical300?: string;
    imageVertical100?: string;
    homepage: string;
    description: string;
    callToAction: string;
    categories: Array<{
        name: string;
    }>;
    organisation: Organisation;
    accountingInformation?: AccountingInformation;
    isActive: boolean;
    isFrozen: boolean;
    isTest: boolean;
    isShowcase: boolean;
    serviceFee: number;
    transparencyJson?: Survey;
}

export const PROJECT_DEFAULT_VALUE = {
    id: -1,
    name: "",
    owner: -1,
    serviceFee: 0,
    homepage: "",
    isActive: false,
    isFrozen: false,
    isTest: false,
    isShowcase: false,
    description: "",
    categories: [{ name: "" }],
    organisation: ORGANISATION_DEFAULT_VALUE,
    accountingInformation: undefined,
    callToAction: "",
};
