import UserProvider from "providers/UserProvider";
import React, { Suspense, lazy, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProgressIndicator from "../components/progress-indicator";
import { AuthContext } from "../providers/AuthProvider";
import SubscribtionProvider from "providers/SubscribtionProvider";

// const Survey = lazy(() => import("./survey"));

const About = lazy(async () => await import("./about"));
const AGB = lazy(async () => await import("./agb"));
const Blog = lazy(async () => await import("./blog"));
const BlogArticle = lazy(async () => await import("./blog/article"));
const CorporateGivingPricing = lazy(async () => await import("./corporate-giving/pricing"));
const Datenschutz = lazy(async () => await import("./datenschutz"));
const FairGeben = lazy(async () => await import("./fairgeben"));
const Faq = lazy(async () => await import("./faq"));
const PartnerWerden = lazy(async () => await import("./partner-werden/partner-werden"));
const GiveGreen = lazy(async () => await import("./givegreen"));
const Home = lazy(async () => await import("./home"));
const Impressum = lazy(async () => await import("./impressum"));
const Jobs = lazy(async () => await import("./jobs"));
const Login = lazy(async () => await import("./login"));
const NewPassword = lazy(async () => await import("./new-password"));
const NotFound = lazy(async () => await import("./not-found"));
const FairGebenPricingContainer = lazy(async () => await import("./pricing"));
const Profil = lazy(async () => await import("./profil"));
const Projektpool = lazy(async () => await import("./projektpool"));
const Registrierung = lazy(async () => await import("./registrierung"));
const Spendenquittung = lazy(async () => await import("./spendenquittung"));
const Spenden = lazy(async () => await import("./spenden"));
const ThankYouPage = lazy(async () => await import("./thank-you-page"));

// https://ui.dev/react-router-protected-routes-authentication
// https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
function RequireAuth({ children }: any) {
    const { token, user } = useContext(AuthContext);
    const location = useLocation();

    if (token && user && user.id && user.username) {
        return children;
    }

    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
}

const RedirectToRollupForm = () => {
    window.location.replace("https://share.hsforms.com/1vRH3wULTQEiCr6up5qYdFQc030a");
    return (
        <></>
    );
};
const EdonRouter = () => (
    <Suspense
        fallback={
            <div
                className="my-5 text-center text-secondary navigation-offset vh-100"
            >
                <ProgressIndicator />
            </div>
        }
    >
        <Routes>
            {
                // TODO: Reimplement Route after all bugs are fixed
                /*
         <Route path="/survey" element={Survey} /> */
            }
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="agb" element={<AGB />} />
            {/* <Route path="agb/:version" element={<AGB />} /> */}
            <Route path="blog" element={<Blog />} />
            <Route path="blog/article/:slug" element={<BlogArticle />} />
            <Route path="corporate-giving/pricing" element={<CorporateGivingPricing />} />
            <Route path="datenschutz" element={<Datenschutz />} />
            <Route path="fairgeben" element={<FairGeben />} />
            <Route path="faq" element={<Faq />} />
            <Route path="givegreen" element={<GiveGreen />} />
            <Route path="impressum" element={<Impressum />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="login" element={<Login />} />
            <Route path="spenden" element={<Spenden />} />
            <Route path="thank-you" element={<ThankYouPage />} />
            <Route path="partner-werden" element={<PartnerWerden />} />
            <Route path="password-reset/" element={<NewPassword />} />
            <Route path="projektpool" element={<Projektpool />} />
            <Route path="pricing" element={<FairGebenPricingContainer />} />
            <Route path="profil/*" element={
                <RequireAuth>
                    <UserProvider>
                        <SubscribtionProvider>
                            <Profil />
                        </SubscribtionProvider>
                    </UserProvider>
                </RequireAuth>
            } />
            <Route path="qr-flyer-09-21" element={<Navigate to="/jobs" />} />
            <Route path="qr-rollup-09-21" element={<RedirectToRollupForm />} />
            <Route path="registrierung" element={<Registrierung />} />
            <Route path="spendenquittung" element={<Spendenquittung />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>
);
export default EdonRouter;