import { USER_DEFAULT_VALUE, type User } from "./user";

export interface BlogAuthor {
    user: User;
    image: string;
    image1080: string;
    image720: string;
    image480: string;
    image144: string;
    description: string;
}

export interface BlogImage {
    name: string;
    altText: string;
    image: string;
    image1080: string;
    image720: string;
    image480: string;
    image144: string;
}

export interface BlogContentImage {
    description: string;
    image: BlogImage;
}

export interface Article {
    id: number;
    title: string;
    subtitle: string;
    author: BlogAuthor;
    heroImage: BlogImage;
    articleImages: BlogContentImage[];
    keywords: string;
    anchorText: string;
    content: string;
    references: RefObj[];
    created: string;
    edited: string;
    slug: string;
}

export const ARTICLE_DEFAULT_VALUE = {
    id: 1,
    title: "",
    subtitle: "",
    slug: "",
    author: {
        user: USER_DEFAULT_VALUE,
        image: "",
        image1080: "",
        image720: "",
        image480: "",
        image144: "",
        description: "",
    },
    heroImage: {
        name: "",
        altText: "",
        image: "",
        image1080: "",
        image720: "",
        image480: "",
        image144: "",
    },
    articleImages: [],
    keywords: "",
    anchorText: "",
    content: "",
    references: [],
    created: "",
    edited: "",
};

export interface RefObj {
    reference: string;
}