import React, { Component } from "react";
import { FontAwesomeIcon, type FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

interface ProgressIndicatorProps {
    className: string,
    size: FontAwesomeIconProps["size"]
}

export default class ProgressIndicator extends Component<ProgressIndicatorProps> {
    public static defaultProps = {
        className: "",
        size: "3x",
    };

    render() {
        return (
            <FontAwesomeIcon
                icon="spinner"
                spin
                size={this.props.size}
                className={this.props.className}
            />
        );
    }
}