import React, { useContext, useEffect, useState } from "react";
import type { Subscription } from "types";
import fetchWithCamelCase from "utils/fetch";
import GLOBALS from "../globals";
import { AuthContext } from "./AuthProvider";

export interface SubscribtionContextInterface {
    subscription: Subscription | null;
    setSubscription: (subscribion: Subscription | null) => void;
    isSubscribed: boolean;
    isTrial: boolean;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    fetchSubscribtion: () => void;
};

const SUBSCRIBTION_CONTEXT_DEFAULT_VALUE = {
    subscription: null,
    setSubscription: (subscribion: Subscription | null) => { console.log("Not Implemented") },
    isSubscribed: false,
    isTrial: false,
    isLoading: false,
    setIsLoading: (isLoading: boolean | null) => { console.log("Not Implemented") },
    fetchSubscribtion: () => { console.log("Not Implemented") },
};

export const SubscribtionContext = React.createContext<SubscribtionContextInterface>(SUBSCRIBTION_CONTEXT_DEFAULT_VALUE);

const SubscribtionProvider = ({ children }: any) => {
    const { token } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const isSubscribed = subscription?.id !== -1;
    const isTrial = !!subscription?.isTrial;

    useEffect(() => {
        fetchSubscribtion();
    }, []);

    const fetchSubscribtion = () => {
        fetchWithCamelCase(`${GLOBALS.API_URL}/fairgeben/subscriptions/?mode=active`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then(responseJson => {
                setIsLoading(false);
                if (responseJson.results && responseJson.results.length > 0) {
                    const data = responseJson.results[0];
                    if (data.id) {
                        setSubscription(data);
                    }
                    else {
                        console.error(data);
                    }
                }
                else {
                    console.error(responseJson);
                }
            }).catch((e) => {
                console.error(e);
            });
    };

    const value = {
        subscription,
        setSubscription,
        isSubscribed,
        isTrial,
        isLoading,
        setIsLoading,
        fetchSubscribtion
    };

    return (
        <SubscribtionContext.Provider value={value}>
            {children}
        </SubscribtionContext.Provider>
    );
};

export default SubscribtionProvider;