import React, { Component, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { AuthContext } from "../../providers/AuthProvider";
import "./header-nav.scss";

interface HeaderNavProps {
    backgroundColor: string;
}

export const AuthButton = () => {
    const { token, user } = useContext(AuthContext);

    if (token && user.id) {
        return (
            <NavLink className="nav-link" to={"/profil"}>
        Profil
            </NavLink>
        );
    }

    return (
        <>
            <NavLink className="btn btn-outline-secondary nav-link ml-1" to="/login">
        Login
            </NavLink>
        </>
    );
};

export default class HeaderNavView extends Component<HeaderNavProps> {
    render() {
        return (
            <Navbar
                collapseOnSelect
                expand="sm"
                fixed="top"
                variant="dark"
                style={{ backgroundColor: this.props.backgroundColor }}
            >
                <Container>
                    <NavbarBrand>
                        <Link
                            to="/"
                            className="text-white text-decoration-none d-flex flex-column text-center"
                        >
                            <div>edon</div>
                            <div className="brand-subtitle">electronic donations</div>
                        </Link>
                    </NavbarBrand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <NavLink className="nav-link" to="/fairgeben">
                FairGeben
                            </NavLink>
                            <NavLink className="nav-link" to="/projektpool">
                Projektpool
                            </NavLink>
                            <NavLink className="nav-link" to="/partner-werden">
                Partner Werden
                            </NavLink>
                            <NavLink className="nav-link" to="/about">
                About
                            </NavLink>

                            <AuthButton />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}
