import camelCaseKeys from "camelcase-keys";
import decamelizeKeys from "decamelize-keys";

const pipe = (...functions: Array<((value: any) => any)>) => (value: any) =>
    functions.reduce((currValue, currFunc) => currFunc(currValue), value);

const requestMiddlewares: Record<string, (init: RequestInit) => RequestInit> = {
    bodyDecamelizer: (init) => {
        if (init.body) {
            let bodyCopy = init.body;

            if (typeof init.body === "string") {
                bodyCopy = JSON.parse(init.body);
            }

            // Check for FormData and exclude it from processing
            if (bodyCopy instanceof FormData) {
                return init;
            }

            // TODO: Why are we excluding Arrays from this conversion?
            if (typeof bodyCopy === "object" && !Array.isArray(bodyCopy)) {
                init.body = JSON.stringify(decamelizeKeys(bodyCopy, { deep: true }));
                init.headers = {
                    ...(init.headers ?? {}),
                    "Content-Type": "application/json",
                };
            }
        }
        return init;
    },
};

const responseMiddlewares: Record<string, (responseData: any) => any> = {
    dataCamelizer: (responseData: any) => camelCaseKeys(responseData, { deep: true }),
    consoleLogger: (responseData: any) => {
        console.log(responseData);
        return responseData;
    },
};

/**
 * A custom wrapper for the built-in `fetch()` function that can convert keys of a `POST`ed body from `camelCase` to `snake_case`
 * and also convert the keys of the `Response` from `snake_case` to `camelCase`.
 * It is based on {@link https://codebrahma.com/intercepting-the-case-battle-between-snakes-and-camels-in-front-end-javascript/ this article}
 * and has been typed and refined using Chat GPT.
 */
const fetchWithCamelCase = ((nativeFetch: typeof window.fetch) => {
    return async (...args: Parameters<typeof window.fetch>) => {
        const [url, init] = args;
        let updatedInit;

        if (init) {
            updatedInit = pipe(
                requestMiddlewares.bodyDecamelizer,
            )(init);
        }

        const responsePromise = nativeFetch.apply(this, [url, updatedInit]);

        return await responsePromise
            .then(response => response.json()) // eslint-disable-line @typescript-eslint/promise-function-async
            .then(responseData =>
                pipe(
                    responseMiddlewares.dataCamelizer,
                    // responseMiddlewares.consoleLogger
                )(responseData)
            );
    };
})(window.fetch);

export default fetchWithCamelCase;